import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import configuration from '../config/configuration';
import Preloader from '../components/Preloader';
import { useMediaQuery } from 'react-responsive';

const Home = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(false);

    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' })
    const isTabletToDesktop = useMediaQuery({ minWidth: '46.25em', maxWidth: '68.74em' })
    const isDesktop = useMediaQuery({ minWidth: '68.75em' })

    useEffect(() => {
        setLoading(true);
        fetch(process.env.REACT_APP_URL_API + configuration.GET_HOME_BANNERS)
            .then(response => response.json())
            .then(data => setBanners(data.data))
            .catch(error => { console.error(error); })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        console.log(banners);
    }, [banners]);

    return (
        <>
            <div className="general-banner-container">

                {!loading ?
                    banners && (
                        <Swiper
                            style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                            }}
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: true
                            }}
                            pagination={{ clickable: true }}
                            navigation={false}
                            modules={[FreeMode, Navigation, Autoplay, Thumbs]}
                            className="pages-slider"
                        >

                            {banners.map((banner, key) => (
                                <SwiperSlide id={key}>
                                    {banner.files.length > 0 && (
                                        banner.files.map((file, key) => (
                                            <a href={banner.bannerUrl} key={key} target="_blank">
                                                {isMobileToTablet && file.bannerFor === "Mobile" ?
                                                    <img src={`${process.env.REACT_APP_URL_BASE}/Uploads/${file.uploadBanner.storedFileName}`} />
                                                    : null
                                                }
                                                {isTabletToDesktop && file.bannerFor === "table" ?
                                                    <img src={`${process.env.REACT_APP_URL_BASE}/Uploads/${file.uploadBanner.storedFileName}`} />
                                                    : null
                                                }
                                                {isDesktop && file.bannerFor === "Desktop" ?
                                                    <img src={`${process.env.REACT_APP_URL_BASE}/Uploads/${file.uploadBanner.storedFileName}`} />
                                                    : null
                                                }
                                            </a>
                                        ))
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )
                    :
                    <div className="lottie-loading">
                        <Preloader />
                    </div>
                }

                <p className="p-title-home mt-3">SERVICIOS</p>
                <div className="buttons-home-categories">
                    <a href="/reproductores" className="button-home">
                        <img src={require('../img/Reproductores.png')} className="button-home-icon" />
                        <p>Reproductores</p>
                    </a>
                    <a href="/invernada" className="button-home">
                        <img src={require('../img/Invernada.png')} className="button-home-icon" />
                        <p>Invernada</p>
                    </a>

                    <a className="button-home" href="https://v1.elrodeo.com.py/feriaconsumo.php" target='_blank'>
                        <img src={require('../img/Consumo.png')} className="button-home-icon" />
                        <p>Consumo</p>
                    </a>

                    {false && (
                        <a href="/consumo" className="button-home">
                            <img src={require('../img/Consumo.png')} className="button-home-icon" />
                            <p>Consumo</p>
                        </a>
                    )}

                    <a href="/frigorificos" className="button-home">
                        <img src={require('../img/Frigorifico.png')} className="button-home-icon" />
                        <p>Frigorificos</p>
                    </a>
                    <a href="/propiedades-rurales" className="button-home">
                        <img src={require('../img/PropiedadesRurales.png')} className="button-home-icon" />
                        <p>Propiedades Rurales</p>
                    </a>
                </div>

            </div>
            <div className="slider-home mt-2">
                <p className="p-title-home">CONFÍAN EN NOSOTROS</p>
                <Swiper
                    slidesPerView={"5"}
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        740: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1100: {
                            slidesPerView: 5,
                            spaceBetween: 30,
                        },
                        1600: {
                            slidesPerView: 5,
                            spaceBetween: 30,
                        },
                    }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    modules={[Autoplay, FreeMode]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-01.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/La_huella.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-02.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-03.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-04.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-05.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-06.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-07.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-08.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-09.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-10.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-11.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-12.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-13.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-14.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-15.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-16.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-17.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-18.png')} />
                    </SwiperSlide>
                    {/*
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-19.png')} />
                    </SwiperSlide>
                    */}
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-20.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-21.png')} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="img-logo-slider"
                            src={require('../img/logos/ALIANZA BRA-22.png')} />
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default Home;
